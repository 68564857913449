import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import IUser from '@tehzor/tools/interfaces/IUser';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditUserResponse = IUser;

/**
 * Изменяет пользователя
 *
 * @param userId id пользователя
 * @param data данные пользователя
 */
export const makeUserEditRequest = async (userId: string, data: ISavingUser) => {
	const response = await httpRequests.withToken.put<IEditUserResponse>(`users/${userId}`, {
		...data
	});
	return response.data;
};
